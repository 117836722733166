import { paths } from '~/lib/paths'
import locales, { convertToDatoCMSGraphQLLocaleCode } from '~/i18n/locales'
import { ModelApiKey } from '~/types/models'
import { StringMultiLocaleField } from '~/types/generated'

export default function (
  modelApiKey: ModelApiKey,
  allCategorySlugs?: StringMultiLocaleField[],
  allTopicSlugs?: StringMultiLocaleField[]
) {
  const route = useRoute()
  const config = useRuntimeConfig()
  const { defaultLocale } = useI18n()

  const links: { id: string; rel: string; href: string; hreflang?: string }[] = [
    {
      id: 'i18n-can',
      rel: 'canonical',
      href: config.public.appUrl + route.fullPath
    }
  ]

  locales.forEach(loc => {
    const CODE = convertToDatoCMSGraphQLLocaleCode(loc.code)

    //  Exclude unpublished locales
    if (
      modelApiKey !== ModelApiKey.Index &&
      !(route.meta.nuxtI18n as Record<string, string>)[CODE]
    ) {
      return
    }

    const categorySlug = allCategorySlugs && allCategorySlugs?.find(x => x.locale === CODE)
    const topicSlug = allTopicSlugs && allTopicSlugs?.find(x => x.locale === CODE)
    const path = modelApiKey !== ModelApiKey.Index && paths[loc.code][modelApiKey].path

    links.push({
      id: 'i18n-alt-' + loc.lang,
      rel: 'alternate',
      href: [
        config.public.appUrl,
        loc.code,
        path,
        topicSlug?.value,
        categorySlug?.value,
        modelApiKey !== ModelApiKey.Index && (route.meta.nuxtI18n as Record<string, string>)[CODE]
      ]
        .filter(Boolean)
        .join('/'),
      hreflang: loc.lang
    })
  })

  // x-default
  const defaultLocaleX = convertToDatoCMSGraphQLLocaleCode(defaultLocale)
  const categorySlugX = allCategorySlugs && allCategorySlugs?.find(x => x.locale === defaultLocaleX)
  const topicSlugX = allTopicSlugs && allTopicSlugs?.find(x => x.locale === defaultLocaleX)
  const pathX = modelApiKey !== ModelApiKey.Index && paths[defaultLocale][modelApiKey].path

  links.push({
    id: 'i18n-xd',
    rel: 'alternate',
    href: [
      config.public.appUrl,
      defaultLocale,
      pathX,
      topicSlugX?.value,
      categorySlugX?.value,
      modelApiKey !== ModelApiKey.Index &&
        (route.meta.nuxtI18n as Record<string, string>)[defaultLocaleX]
    ]
      .filter(Boolean)
      .join('/'),
    hreflang: 'x-default'
  })

  return links
}
